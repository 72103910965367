import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-akron-ohio.png'
import image0 from "../../images/cities/scale-model-of-riverfront-cruise-in-in-akron-ohio.png"
import image1 from "../../images/cities/scale-model-of-polar-express-in-akron-ohio.png"
import image2 from "../../images/cities/scale-model-of-lock-next-in-akron-ohio.png"
import image3 from "../../images/cities/scale-model-of-fairlawn-kiwanis-community-center-in-akron-ohio.png"
import image4 from "../../images/cities/scale-model-of-portage-path-indian-statue-in-akron-ohio.png"
import image5 from "../../images/cities/scale-model-of-hower-house-at-the-university-of-akron-in-akron-ohio.png"
import image6 from "../../images/cities/scale-model-of-akron-art-museum-in-akron-ohio.png"
import image7 from "../../images/cities/scale-model-of-akron-summit-convention-&-visitors-bureau-in-akron-ohio.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Akron'
            state='Ohio'
            image={image}
            lat='41.0814447'
            lon='-81.5190053'
            attractions={ [{"name": "Riverfront Cruise In", "vicinity": "2200 Front St, Cuyahoga Falls", "types": ["point_of_interest", "establishment"], "lat": 41.1364648, "lng": -81.4827664}, {"name": "Polar Express", "vicinity": "27 Ridge St, Akron", "types": ["point_of_interest", "establishment"], "lat": 41.0895003, "lng": -81.51502640000001}, {"name": "Lock Next", "vicinity": "200 S Main St, Akron", "types": ["point_of_interest", "establishment"], "lat": 41.079596, "lng": -81.52020089999996}, {"name": "Fairlawn Kiwanis Community Center", "vicinity": "3486 S Smith Rd, Fairlawn", "types": ["point_of_interest", "establishment"], "lat": 41.1333637, "lng": -81.627567}, {"name": "Portage Path Indian Statue", "vicinity": "Akron", "types": ["point_of_interest", "establishment"], "lat": 41.0978606, "lng": -81.54578049999998}, {"name": "Hower House at The University of Akron", "vicinity": "60 Fir Hill, Akron", "types": ["museum", "point_of_interest", "establishment"], "lat": 41.07963, "lng": -81.50774639999997}, {"name": "Akron Art Museum", "vicinity": "1 S High St, Akron", "types": ["library", "museum", "point_of_interest", "establishment"], "lat": 41.084313, "lng": -81.51536290000001}, {"name": "Akron-Summit Convention & Visitors Bureau", "vicinity": "77 E Mill St, Akron", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 41.082854, "lng": -81.51574590000001}] }
            attractionImages={ {"Riverfront Cruise In":image0,"Polar Express":image1,"Lock Next":image2,"Fairlawn Kiwanis Community Center":image3,"Portage Path Indian Statue":image4,"Hower House at The University of Akron":image5,"Akron Art Museum":image6,"Akron-Summit Convention & Visitors Bureau":image7,} }
       />);
  }
}